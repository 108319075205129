import { createSlice } from "@reduxjs/toolkit"

const currentYear = new Date().getFullYear().toString()

const initialState = {
  globalYear: currentYear,
  refresh: 0
}

export const generalFunctionsSlice = createSlice({
  name: "generalFunctions",
  initialState,
  reducers: {
    setYear: (state, action) => {
      state.globalYear = action.payload
    },
    triggerRefresh: (state) => {
      state.refresh = state.refresh + 1
    }
  }
})

export const { setYear, triggerRefresh } = generalFunctionsSlice.actions
export default generalFunctionsSlice.reducer
